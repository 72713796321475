var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"py-8",attrs:{"fluid":""}},[(_vm.loading)?_c('Loading'):_vm._e(),_c('Notify'),_c('div',{staticClass:"mt-10"},[_c('v-app-bar',{staticClass:"mb-5 border-left-primary",attrs:{"color":"light"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("sidebar.Register Form")))]),(
            _vm.$store.getters['auth/str_per'].indexOf(
              'customer-register-form-create'
            ) > -1
          )?_c('button',{staticClass:"py-5 mb-0 ms-auto",on:{"click":function($event){return _vm.add()}}},[_c('v-icon',{staticClass:"custome-btn btn-primary"},[_vm._v("fas fa-plus")])],1):_vm._e()],1),(_vm.tab_inqueries.length > 0)?_c('div',{attrs:{"id":"stepWizard"}},[_c('ul',{staticClass:"nav-wizard"},_vm._l((_vm.tab_inqueries),function(item,i){return _c('li',{key:i,staticClass:"tab-link",class:[_vm.activeTab == i ? 'active' : ''],on:{"click":function($event){return _vm.activeity(i)}}},[_c('span',[_vm._v(_vm._s(item.name))])])}),0)]):_vm._e(),_c('div',{staticClass:"tabs_content mt-8"},_vm._l((_vm.tab_inqueries),function(item,i){return (_vm.activeTab == i)?_c('v-card',{key:i,staticClass:"card-shadow card-padding pt-5"},[_c('div',[_c('div',{staticClass:"text-right"},[_c('div',{staticClass:"d-inline-block"},[(
                    _vm.$store.getters['auth/str_per'].indexOf(
                      'customer-register-form-update'
                    ) > -1
                  )?_c('span',{class:"btn font-weight-bold  mr-4",attrs:{"title":_vm.$t('general.edit')},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(
                    _vm.$store.getters['auth/str_per'].indexOf(
                      'customer-register-form-delete'
                    ) > -1
                  )?_c('span',{class:"btn font-weight-bold  mr-4",attrs:{"title":_vm.$t('general.edit')},on:{"click":function($event){return _vm.destroy(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()])])]),_vm._l((item.get_inquiries),function(inquery,index){return _c('v-list-item',{key:index,staticClass:"py-3"},[_c('v-list-item-content',{staticClass:"py-0"},[_c('div',{staticClass:"d-flex flex-column text-start"},[_c('span',{staticClass:"text-sm text-typo mb-3 font-weight-bold"},[_vm._v(" "+_vm._s(inquery.inquery_lang)+" ")])])])],1)})],2):_vm._e()}),1)],1),_c('Modal',{attrs:{"data_modal":_vm.modal_data,"data":_vm.form_data,"loader":_vm.loader_form,"inquieries":_vm.inquieries_modal},on:{"save":_vm.save_data}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }