<template>
  <div>
    <v-container fluid class="py-8">
      <Loading v-if="loading"></Loading>
      <Notify></Notify>
      <div class="mt-10">
        <v-app-bar color="light" class="mb-5 border-left-primary">
          <v-toolbar-title>{{ $t("sidebar.Register Form") }}</v-toolbar-title>
          <button
            v-if="
              $store.getters['auth/str_per'].indexOf(
                'customer-register-form-create'
              ) > -1
            "
            class="py-5 mb-0 ms-auto"
            @click="add()"
          >
            <v-icon class="custome-btn btn-primary">fas fa-plus</v-icon>
          </button>
        </v-app-bar>
        <div id="stepWizard" v-if="tab_inqueries.length > 0">
          <ul class="nav-wizard">
            <li
              v-for="(item, i) in tab_inqueries"
              :key="i"
              @click="activeity(i)"
              :class="[activeTab == i ? 'active' : '']"
              class="tab-link"
            >
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
        <div class="tabs_content mt-8">
          <v-card
            v-for="(item, i) in tab_inqueries"
            :key="i"
            v-if="activeTab == i"
            class="card-shadow card-padding pt-5"
          >
            <div>
              <div class="text-right">
                <div class="d-inline-block">
                  <span
                    v-if="
                      $store.getters['auth/str_per'].indexOf(
                        'customer-register-form-update'
                      ) > -1
                    "
                    :title="$t('general.edit')"
                    :class="`btn font-weight-bold  mr-4`"
                    @click="edit(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </span>
                  <span
                    v-if="
                      $store.getters['auth/str_per'].indexOf(
                        'customer-register-form-delete'
                      ) > -1
                    "
                    :title="$t('general.edit')"
                    :class="`btn font-weight-bold  mr-4`"
                    @click="destroy(item.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </span>
                </div>
              </div>
            </div>
            <v-list-item
              class="py-3"
              v-for="(inquery, index) in item.get_inquiries"
              :key="index"
            >
              <v-list-item-content class="py-0">
                <div class="d-flex flex-column text-start">
                  <span class="text-sm text-typo mb-3 font-weight-bold">
                    {{ inquery.inquery_lang }}
                  </span>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </div>
      <Modal
        :data_modal="modal_data"
        :data="form_data"
        :loader="loader_form"
        :inquieries="inquieries_modal"
        @save="save_data"
      ></Modal>
    </v-container>
  </div>
</template>
<script>
import Modal from "./Modal.vue";
import Loading from "../../Components/Loading.vue";
import Notify from "../../Components/New/Notify.vue";
// import customerService from "../../../services/customer.service";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("customer");
export default {
  name: "Profile-Customer",
  components: {
    Modal,
    Loading,
    Notify,
  },
  data() {
    return {
      id: null,
      form_data: {
        selected: [],
        tab: "",
        form_data_inqueries: [{ inquiry_id: "", is_required: false }],
      },
      loader_form: false,
      loading: false,
      activeTab: 0,
      modal_data: {
        size: "600px",
        title: "",
        dialog: false,
      },
      message: {
        msg: "",
        type: "",
      },
      inquieries_modal: [],
      type_modal: 0,
    };
  },
  computed: {
    ...mapState(["tab_inqueries", "inqueries"]),
    classLang() {
      return {
        "pl-0": !this.$vuetify.rtl,
        "pr-0": this.$vuetify.rtl,
      };
    },
  },
  methods: {
    ...mapActions(["getTabsInquery", "saveTabInquery", "destroyTabInquiry"]),
    activeity(val) {
      this.activeTab = val;
    },
    getDetails() {
      this.loading = true;

      this.getTabsInquery().then(
        (response) => {
          // this.activeTab = this.tab_inqueries[0].id;
          this.inquieries_modal = this.inqueries;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          // console.log(error);
        }
      );
    },
    add() {
      this.type_modal = 0;
      this.id = null;
      this.modal_data.dialog = true;
      this.modal_data.title = this.$i18n.t("create new section");
    },
    edit(item) {
      this.type_modal = 1;
      this.form_data.form_data_inqueries = [];
      console.log(this.form_data.selected);
      this.modal_data.dialog = true;
      this.modal_data.title = this.$i18n.t("edit section") + item.name;
      this.form_data.tab = item.name;
      this.id = item.id;
      for (let i = 0; i < item.get_inquiries.length; i++) {
        const element = item.get_inquiries[i];
        this.form_data.form_data_inqueries.push({
          inquiry_id: parseInt(element.register_form_inquiry.inquiry_id),
          is_required: element.register_form_inquiry.is_required,
        });
        this.form_data.selected.push(
          parseInt(element.register_form_inquiry.inquiry_id)
        );
      }
    },
    save_data(data, valid) {
      this.loader_form = true;
      if (valid) {
        this.saveTabInquery({
          data: data,
          type: this.type_modal,
          id: this.id,
        }).then(
          (res) => {
            this.loader_form = false;
            if (res.data.code && res.data.code == 500) {
              this.$store.dispatch("form/setNotify", {
                msg: res.data.message,
                type: "Danger",
              });
            } else {
              if (this.type_modal == 0) {
                this.activeTab = this.tab_inqueries.length - 1;
              }
              this.modal_data.dialog = false;
              this.$store.dispatch("form/setNotify", {
                msg: res.data.message,
                type: "Success",
              });
            }
          },
          (error) => {
            this.loader_form = false;
            if (error.response.status == 422) {
              let errors = Object.values(error.response.data.errors);
              var msg = errors.toString();
              this.$store.dispatch("form/setNotify", {
                msg: msg,
                type: "Danger",
              });
            } else {
              this.$store.dispatch("form/setNotify", {
                msg: this.$i18n.t("general.there is problem"),
                type: "Danger",
              });
            }
          }
        );
      } else {
        this.loader_form = false;
      }
    },
    destroy(id) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("general.Yes, delete it!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.destroyTabInquiry(id).then(
            (response) => {
              // console.log(response)
              this.$swal.fire(
                this.$i18n.t("general.Deleted!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("general.Cancelled Delete"),
            "error"
          );
        }
      });
    },
  },
  mounted() {
    this.getDetails();
    document.title = this.$i18n.t("sidebar.Register Form");
  },
};
</script>

